<template lang="pug">
  .Home
    div
      .mx-5
        .buttons.mt-10(v-if="(!datesFromUser || !datesFromUser.length || trip === 'Home') && !loading && trip !== 'Home_appointments'")
          button-component.font-semibold(
            v-for="(eps, index) in epsList"
            :key="index"
            :text="eps.name"
            :onClick="() => handleSelectEps(eps)"
            themeSelected="light"
            class="hover:bg-secondary hover:text-white hover:font-thin"
            :class="`button-${index + 1}`"
          ).buttonDynamic.btn.transition.duration-300
        .pb-24(v-if="trip === 'Home_dates'")
          .dates(v-if="datesFromUser && datesFromUser.length")
            h3.text-primary.text-xl.m-0.font-semibold.mt-7.mb-3 Aquí tienes un resumen de tus citas
            ul.mt-2
              card-date-resumen(v-for="date in datesFromUser" :key="date.id" :date="date")
        .pb-24(v-if="trip === 'Home_appointments'")
          div.flex.my-5
            .inline-block
              div.mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
                span(class="material-icons-outlined").text-primary place
            p.text-info_color.m-0.inline-block.ml-5
              | Estas son las droguerias donde te podemos entregar tus medicamentos.
          hr.opacity-20
          ul.mt-2(v-if="drugstoresList && drugstoresList.length")
            card-date(v-for="drugstore in drugstoresList" :key="drugstore.id" :date="drugstore")
          ul.mt-2(v-else)
            br
            | No se encontraron puntos para dispensar la fórmula. 
            br
            br
            div 
              b Una vez realizada la validación de su fórmula se identifica en sistema que actualmente los puntos de dispensación para agendamiento en su ciudad no cuentan con disponibilidad.
            div
                b Lo invitamos a que se acerque al punto en el cual suele reclamar sus medicamentos para recibir más información y poder atender su solicitud
            br
            button-component(
              text="Aceptar"
              themeSelected="secondary"
              :onClick="handleGoHome"
            )
        //- p(v-if="loading").m-5.text-center Cargando . . .
    footer(v-if="(datesFromUser && datesFromUser.length) && trip === 'Home_dates'").Footer.fixed.p-5.pt-2.max-w-lg.w-full.bg-white.z-90
      button-component(
        text="Agendar nueva cita"
        themeSelected="secondary"
        :onClick="handleAgendationDate"
      )
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import _ from 'underscore';

export default {
  name: "Home",
  data: () => {
    return {
      modeDates: false,
      responsesNoDates: [],
    };
  },
  computed: {
    ...mapState({
      trip: (state) => state.root.trip,
      user: (state) => state.user.userData,
      epsList: (state) => state.environment.environment.entities,
      loading: (state) => state.environment.loading,
      // drugstoresList: (state) => state.drugstore.drugstoresList,
      points: (state) => state.environment.environment.points,
      eps: (state) => state.eps.epsState,
      formDocuments: (state) => state.formDocuments.formDocumentsState,
      appointments: (state) => state.appointments.appointments,
      maintenance: (state) => state.environment.environment.maintenance
    }),
    datesFromUser() {
      return this.appointments;
    },
    drugstoresList(){
      if(this.points){

        // filter by eps
        let points = this.points.filter(p=> p.entity_code == this.eps.code);
        
        // filter by points from cafam api
        let filter_points = [];
        if(this.formDocuments){
          for (let index = 0; index < this.formDocuments.length; index++) {
            const document = this.formDocuments[index];
            for (let i = 0; i < points.length; i++) {
              let p = _.clone(points[i]);
              if(document.puntos.includes(String(p.cost_center)) || document.puntos.includes(p.cost_center)){
                p.number = document.numero;
                filter_points.push(p);
              }
            }
          }
        }

        // many points iquals for many numbers
        let points_obj = {};
        filter_points.forEach(element => {
          if(points_obj[element.cost_center]){
            points_obj[element.cost_center].numbers.push(element.number);
          }else{
            element.numbers = [element.number];
            points_obj[element.cost_center] = element;
          }
        });
        let points_full = [];
        for (let index in points_obj) {
          const element = points_obj[index];
          points_full.push(element);
        }
        
        points_full = _.sortBy( points_full, 'name' );

        return  points_full.map(p=>{
          delete p.code;  
          return p;
        })
      } else return [];
    }
  },
  watch:{
    maintenance(value){
      if(value) this.$router.push({ path: "/500", });
    }
  },
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    CardDateResumen: () => import("../components/CardDateResumen.vue"),
    CardDate: () => import("../components/CardDate.vue"),
  },
  created() {
    // localStorage.setItem("usersNames", JSON.stringify([
    //   {
    //     dcType: "CC",
    //     documentType: "Cedula de ciudadania",
    //     documentNumber: 1000778042,
    //     cityOrMunicipio: "Manizales",
    //     cityId: 5,
    //     confirmCafam: true,
    //     name: "LOPEZ GALINDO PUETERO",
    //     eps: {
    //       code: 2,
    //     },
    //   },
    //   {
    //     dcType: "CC",
    //     documentType: "Cedula de ciudadania",
    //     documentNumber: 14784555551,
    //     cityOrMunicipio: "Bogota",
    //     cityId: 1,
    //     confirmCafam: true,
    //     name: "YARILING SOTO SAMUNDIA",
    //     eps: {
    //       code: 1,
    //     },
    //   }
    // ]));
    if (!this.user.dates) {
      this.getAllDataToNoDates();
    } else {
      // this.setState({ key: "specialGoBack", value: "ValidationData" });
      console.log("call dates");
    }
  },
  mounted(){
    if(this.maintenance) this.$router.push({ path: "/500", });
  },
  methods: {
    ...mapActions("eps", [
      "getEpsList",
    ]),
    ...mapActions("root", [
      "changeTrip",
      "changeUserData",
    ]),
    ...mapMutations("eps", [
      "setState",
    ]),
    async getAllDataToNoDates() {
      this.responsesNoDates = await Promise.all([
        this.getEpsList(),
      ]);
    },
    handleSelectEps(eps) {
      this.setState({ key: "epsState", value: eps });
      this.changeTrip("ID");
      this.changeUserData({
        documentType: "",
        documentNumber: "",
        cityOrMunicipio: "",
        confirmCafam: "",
      });
    },
    handleAgendationDate() {
      this.changeTrip("FormDocumentsAdd");
    },
    handleGoHome(){
      this.changeTrip("Home");
    }
  },
}
</script>

<style scoped>
  .btn {
    /* transition: 1s all; */
    opacity: 0;
    will-change: opacity transform;
    animation: fade 0.3s alternate forwards;
  }
  .button-2 {
    animation-delay: 0.4s;
  }
  .button-3 {
    animation-delay: 0.7s;
  }
  .Footer2 {
    height: 116px;
  }
  .buttonDynamic {
    margin-bottom: 3vh;
  }
</style>